.main {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #3d25141f;
  width: 100%;
  overflow: auto;
  min-height: 400px;
  padding: 24px 14px 14px 24px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 40px 16px 16px;
  width: 240px;
  position: sticky;
  top: 0;
  height: 100%;
  overflow: auto;
}

.versions {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 8px;
  padding-bottom: 16px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 52px;
}

.buttons {
  width: 100%;
  background: #fff;
  padding: 10px;
  position: fixed;
  z-index: 1;
  height: 80px;
  padding-bottom: 24px;
  bottom: 0;
  border-top: 1px solid #3d25141f;
}

.title {
  width: 100%;
  background: #fff;
  padding: 10px;
  position: fixed;
  z-index: 1;
  height: 80px;
  padding-top: 40px;
  top: 0;
}