.customButton {
  background-color: #E3F5FF;
  border: 1px solid #C3C4CD;
  border-radius: 6px;
  color:  var(--mantine-color-dark-7);
  padding: 6px 12px;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: var(--mantine-color-blue-1);
    color:  var(--mantine-color-dark-7);
  }
}