.responderCard {
  border: 1px solid #D1EFF8;
  background-color: #F0F7F980;
  border-radius: 24px;
  padding: 16px;
}

.intermediateResponderCard {
  border: 1px solid #FEBB84;
  background-color: #F9F4F080;
  border-radius: 24px;
  padding: 16px;
}

.declinedResponderCard {
  border: 1px solid #9b001c3b;
  background-color: #ffece82f;
  border-radius: 24px;
  padding: 16px;
}