.body {
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 0;
  padding-top: 0;
  gap: 8px;
}